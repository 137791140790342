<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col class="col-12">
				<div class="table-responsive">
					<table class="table table-hover table-striped">
						<thead>
							<tr>
								<th class="border-top-0 text-left">
									{{ translate('name') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('order_paid_date') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('order_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('invoice_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('user_id') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('ship_to') }}
								</th>
								<th class="border-top-0 text-left">
									{{ translate('country') }}
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<transition
								v-for="(item, index) in data"
								:key="index"
								name="slide-fade">
								<tr
									v-if="isParent(item) || showChild(item)"
									@click="handleRowClick(item)">
									<td
										:class="`align-middle text-left ${customClasses(item)}`"
										:colspan="isParent(item) ? 7 : 1">
										{{ item.is_parent ? item.name : '' }}
									</td>
									<template v-if="!isParent(item)">
										<td class="align-middle text-left">
											{{ $moment(item.order_paid_date.date).format(datetimeFormat) }}
										</td>
										<td class="align-middle text-left">
											{{ item.order_id }}
										</td>
										<td class="align-middle text-left">
											{{ item.invoice_id }}
										</td>
										<td class="align-middle text-left">
											<span class="badge badge-primary">{{ item.user_id }}</span>
										</td>
										<td class="align-middle text-left">
											{{ item.ship_to }}
										</td>
										<td class="align-middle text-left">
											<img
												v-b-tooltip.hover
												class="ml-3"
												:title="translate(item.country_code.toLowerCase())"
												:src="require(`@/assets/images/common/countries/select/${item.country_code.toUpperCase()}.png`)"
												:alt="translate(item.country_code.toLowerCase())">
										</td>
									</template>
									<td :class="`align-middle text-center ${customClasses(item)}`">
										<div v-if="isParent(item)">
											<b-button
												v-b-tooltip.hover.left
												v-b-modal="'update_stock_status'"
												:title="translate('export')"
												variant="primary"
												class="mr-1 bg-primary-alt"
												@click.stop="exportBatch(item)">
												<i class="fas fa-file-download" />
											</b-button>
										</div>
									</td>
								</tr>
							</transition>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="hasData" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import WarehouseOrdersUtil from '@/util/WarehouseOrders';
import { Countries, Grids, WarehouseOrders } from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'ReadyForShipmentWarehouseOrders',
	messages: [Grids, Countries, WarehouseOrders],
	data() {
		return {
			warehouseId: null,
			batches: new WarehouseOrdersUtil(),
			download: new WarehouseOrdersUtil(),
			currentExpandIds: [],
			datetimeFormat: YMDHMS_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.batches.data.loading;
		},
		hasData() {
			return this.data.length > 0;
		},
		data() {
			try {
				const data = [];
				const batches = this.batches.data.response.data.data;
				batches.forEach((batch) => {
					data.push(this.formatRow(batch, null));
					batch.attributes.items.forEach((item) => {
						data.push(this.formatRow(item, batch.id));
					});
				});
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.warehouseId = this.$route.query.warehouseId;
		this.getData();
	},
	methods: {
		getData() {
			if (this.warehouseId) {
				this.batches.getBatches(this.warehouseId);
			}
		},
		formatRow(row, parentId = null) {
			const isChild = parentId !== null;
			if (isChild) {
				return {
					id: row.id,
					is_parent: false,
					parent_id: parentId,
					order_id: row.order_id,
					order_paid_date: row.order_paid_date,
					country_code: row.country_code,
					user_id: row.user_id,
					ship_to: row.ship_to,
					invoice_id: row.invoice_id,
				};
			}

			return {
				id: row.id,
				is_parent: true,
				name: row.attributes.name,
				order_paid_date: null,
				country_code: null,
				user_id: null,
				ship_to: null,
				invoice_id: null,
			};
		},
		isParent(item) {
			return item.is_parent;
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.parent_id));
		},
		handleRowClick(item) {
			if (!item.is_parent) return;
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}
		},
		customClasses(item) {
			if (this.isParent(item)) {
				return 'pointer';
			}
			return 'text-medium';
		},
		exportBatch(item) {
			this.download.exportBatch({
				batch_id: item.id,
			}).then(() => {
				const { response } = this.download.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				const now = new Date();
				const filename = `warehouse_orders_${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;
				link.setAttribute('download', filename);
				this.$el.appendChild(link);
				link.click();
			});
		},
	},
};
</script>

<style scoped>
.slide-fade-enter-active {
	transition: all .5s ease;
}
.slide-fade-leave-active {
	transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
	transform: translateX(10px);
	opacity: 0;
}
</style>
